

























































































import Vue from "vue";
import { mapState } from "vuex";
import WOW from "wow.js";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  },
  mounted() {
    setTimeout(() => {
      new WOW({ animateClass: "animate__animated", offset: 100 }).init();
    }, 1000);
  }
});
